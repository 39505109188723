import React from 'react';
import VisibleIcon from '@mui/icons-material/Done';
import NotVisibleIcon from '@mui/icons-material/Block';
import {
  CustomFormProps,
  Form,
  useEnhancedForm,
} from '../form';
import useResourceTranslator from '../resource/useResourceTranslator';
import { Shop } from './useShopApi';
// @ts-ignore
import PaperTable from '../components/PaperTable';
// @ts-ignore
import PaperTableRow from '../components/PaperTableRow';

const DEFAULT_VALUES: Shop | {} = {};

export interface EditShopFormProps extends CustomFormProps<Shop | {}> {}

export default function ViewShopForm({
  defaultValues = DEFAULT_VALUES,
  errors = {},
  onSubmit,
}: EditShopFormProps) {
  const t = useResourceTranslator();
  const data = defaultValues as Shop;
  const {
    handleSubmit,
  } = useEnhancedForm({ defaultValues, errors });

  return (
    <>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <PaperTable headline={t('shop.general')}>
          <PaperTableRow
            label={t('shop.visible')}
            value={data.visible ? <VisibleIcon /> : <NotVisibleIcon />}
          />
          <PaperTableRow
            label={t('shop.isPreLaunch')}
            value={data.isPreLaunch ? <VisibleIcon /> : <NotVisibleIcon />}
          />
        </PaperTable>

        <PaperTable headline={t('shop.opening')}>
          {data.openingHoursSpecification?.length > 0
            ? data.openingHoursSpecification.map(val => (
              <PaperTableRow
                label={t(`days.${val.dayOfWeek}`)}
                value={val.opens ? `${val.opens} - ${val.closes}` : t('shop.noSpec')}
              />
            ))
            : <PaperTableRow label={t('shop.noSpec')} />
                }
        </PaperTable>

        <PaperTable headline={t('shop.networks')}>
          {data.customerNetworks?.length > 0
            ? data.customerNetworks.map(network => (
              <PaperTableRow label={t('shop.ssid')} value={network.ssid} key={network.ssid} />
            ))
            : <PaperTableRow label={t('shop.noNetworks')} />
            }
        </PaperTable>

        <PaperTable headline={t('shop.services')}>
          {data.services?.length > 0
            ? data.services.map(service => (
              <PaperTableRow label={t(`shopServices.${service}`)} />
            ))
            : <PaperTableRow label={t('shop.noServices')} />
            }
        </PaperTable>
      </Form>
    </>
  );
}
