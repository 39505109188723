/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from 'react';
import { CustomFormProps } from '../form';
// @ts-ignore
import PaperTable from '../components/PaperTable';
// @ts-ignore
import PaperTableRow from '../components/PaperTableRow';
import Translate from '../components/i18n/Translate';
import CheckoutDevicesStatusIndicator from './CheckoutDevicesStatusIndicator';
// @ts-ignore
import LocaleDate from '../components/LocaleDate';
import { Status } from './CheckoutDevice';
import { DeviceStatus, buildOSVersionString, buildTerminalTypesList, buildTerminalVersionsList, buildTimestampString, buildTransactionInfoString } from './CheckoutDeviceHelper';
import { buildShopLabel } from '../shop';
import useProjectNavigate from '../useProjectNavigate';

interface CheckoutDeviceQuickEditViewProps extends CustomFormProps<any | {}> {}

export default function CheckoutDeviceQuickEditView({
  defaultValues = {},
}: CheckoutDeviceQuickEditViewProps) {
  const navigate = useProjectNavigate();
  const checkoutDevice = defaultValues;

  return (
    <>
      <PaperTable>
        <PaperTableRow
          label={<Translate id="checkoutDevices.id" />}
          value={checkoutDevice.id}
        />
        <PaperTableRow
          label={<Translate id="checkoutDevices.name" />}
          value={checkoutDevice.name}
        />
        <PaperTableRow
          label={<Translate id="checkoutDevices.externalID" />}
          value={checkoutDevice.externalID || '-/-'}
        />
        <PaperTableRow
          label={<Translate id="checkoutDevices.shop" />}
          value={
            <div
              onClick={() => { navigate(`/shops/${checkoutDevice.shop.id}`); }}
              style={{
                cursor: 'pointer',
                textDecoration: 'underline',
              }}
            >
              {buildShopLabel(checkoutDevice.shop)}
            </div>
          }
        />
        <PaperTableRow
          label={<Translate id="checkoutDevices.type" />}
          value={<Translate id={`checkoutDevices.types.${checkoutDevice.type}`} defaultValue={checkoutDevice.type} />}
        />
        <PaperTableRow
          label={<Translate id="checkoutDevices.description" />}
          value={checkoutDevice.description || '-/-'}
        />
      </PaperTable>

      <PaperTable>
        <PaperTableRow
          label={<Translate id="checkoutDevices.statusIndicator" />}
          value={
            <CheckoutDevicesStatusIndicator
              status={checkoutDevice.status === Status.UP
                ? DeviceStatus.Online
                : DeviceStatus.Offline
              }
            />
        }
        />
        <PaperTableRow
          label={<Translate id="checkoutDevices.lastSeen" />}
          value={checkoutDevice?.lastSeenAt ? buildTimestampString(checkoutDevice?.lastSeenAt) : '-'}
        />
        {checkoutDevice?.systemInfo?.transactionInfo && (
        <>
          <PaperTableRow
            label={<Translate id="checkoutDevices.transactionDaily" />}
            value={buildTransactionInfoString({
              successful: checkoutDevice.systemInfo.transactionInfo.dailySuccessfulTransactionCount,
              failed: checkoutDevice.systemInfo.transactionInfo.dailyFailedTransactionCount,
              overall: checkoutDevice.systemInfo.transactionInfo.dailyOverallTransactionCount,
            })}
          />
          <PaperTableRow
            label={<Translate id="checkoutDevices.transaction" />}
            value={buildTransactionInfoString({
              successful: checkoutDevice.systemInfo.transactionInfo.successfulTransactionCount,
              failed: checkoutDevice.systemInfo.transactionInfo.failedTransactionCount,
              overall: checkoutDevice.systemInfo.transactionInfo.overallTransactionCount,
            })}
          />
        </>
        )}
      </PaperTable>

      {checkoutDevice?.systemInfo && (
        <PaperTable>
          {!!checkoutDevice.systemInfo.os &&
            <>
              <PaperTableRow
                label={<Translate id="checkoutDevices.osName" />}
                value={buildOSVersionString(checkoutDevice.systemInfo.os)}
              />
              <PaperTableRow
                label={<Translate id="checkoutDevices.osBuild" />}
                value={checkoutDevice.systemInfo.os.build}
              />
            </>
          }
          {checkoutDevice.systemInfo.host &&
            <PaperTableRow
              label={<Translate id="checkoutDevices.bootTime" />}
              value={<LocaleDate date={checkoutDevice.systemInfo.host.bootTime} />}
            />
          }
          {checkoutDevice.systemInfo.process &&
            <>
              <PaperTableRow
                label={<Translate id="checkoutDevices.processVersion" />}
                value={checkoutDevice.systemInfo.process.version}
              />
              <PaperTableRow
                label={<Translate id="checkoutDevices.processStart" />}
                value={<LocaleDate date={checkoutDevice.systemInfo.process.startTime} />}
              />
            </>
              }
          {checkoutDevice.systemInfo.supportedTerminalTypes &&
            <PaperTableRow
              label={<Translate id="checkoutDevices.supportedTerminalTypes" />}
              value={buildTerminalTypesList(checkoutDevice.systemInfo.supportedTerminalTypes)}
            />
          }
          {checkoutDevice.systemInfo.selectedTerminalType &&
            <PaperTableRow
              label={<Translate id="checkoutDevices.selectedTerminalType" />}
              value={checkoutDevice.systemInfo.selectedTerminalType}
            />
          }
          {checkoutDevice.systemInfo.installedTerminalVersions &&
            <PaperTableRow
              label={<Translate id="checkoutDevices.installedTerminalVersions" />}
              value={buildTerminalVersionsList(checkoutDevice.systemInfo.installedTerminalVersions)}
            />
          }
        </PaperTable>
      )}
    </>
  );
}
