import React, { useMemo } from 'react';
import RatingsChart from './RatingsChart';
import CommentTable from './CommentTable';
import { Range } from '../charts/chartFilterProps';
import { mapRatingsDataToChartData, FeedbackData } from './useFeedbackApi';
import shopLabel from '../shop';
import Shop from '../shop/Shop';

export interface FeedbackResourceWrapperProps {
  values: FeedbackData;
  range?: Range;
  ratingsMetadata?: {
    keys: string[],
    legendText: string[],
    legendColors: string[],
  };
  stackedOnInit?: boolean;
  onValueClick?: () => void;
  shopsMap: Map<string | undefined, Shop>;
}

export default function FeedbackResourceWrapper({
  values,
  range,
  ratingsMetadata,
  stackedOnInit,
  onValueClick,
  shopsMap,
}: FeedbackResourceWrapperProps) {
  const ratingsValues = useMemo(() =>
    mapRatingsDataToChartData(values), [values]);

  const commentValues = useMemo(() => (
    values?.comments.map(comment => (
      {
        ...comment,
        shopName: shopLabel(shopsMap.get(comment.shopID)),
      }
    ))
  ), [shopsMap, values?.comments]);

  return (
    <>
      <RatingsChart
        values={ratingsValues}
        range={range}
        ratingsMetadata={ratingsMetadata}
        stackedOnInit={stackedOnInit}
        onValueClick={onValueClick}
      />
      <CommentTable
        values={commentValues}
        range={range}
      />
    </>
  );
}
