import React from 'react';
import { NavLink } from 'react-router-dom';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import MenuItem from '@mui/material/MenuItem';
import withStyles from '@mui/styles/withStyles';
import Tooltip from '@mui/material/Tooltip';

const styles = (theme: any) => ({
  menuItem: {
    padding: 0,
    height: 'auto',

    '& a': {
      width: '100%',
      display: 'flex',
      padding: theme.spacing(2),

      [theme.breakpoints.down(700)]: {
        paddingBlock: theme.spacing(1.5),
      },
    },
  },
  selected: {
    background: 'none !important',
  },
  primary: {},
  primarySelected: {
    color: theme.palette.primary.main,
    fontWeight: 'bold',
  },
  selectedIcon: {
    marginRight: '0px',
    '& svg': {
      '& path': {
        fill: theme.palette.primary.main,
      },
    },
  },
  icon: {
    marginRight: '0px',
    color: 'rgba(0,0,0,0.87)',
  },
});

export interface SidebarMenuItemProps {
  linkText: string;
  linkTarget: string;
  icon: any;
  currentPath?: string;
  isExternal?: boolean;
  isMini?: boolean;
  classes: any;
}

const SidebarMenuItem = ({
  linkText,
  linkTarget,
  icon,
  currentPath,
  isExternal = false,
  isMini = false,
  classes,
}: SidebarMenuItemProps) => {
  if (isExternal) {
    return (
      <MenuItem
        className={classes.menuItem}
      >
        <a href={linkTarget}>
          <Tooltip title={isMini && linkText} placement="right" arrow>
            <ListItemIcon
              style={{ marginRight: '8px' }}
              className={classes.icon}
            >
              {icon}
            </ListItemIcon>
          </Tooltip>
          <ListItemText
            classes={{ primary: classes.primary }}
            primary={linkText}
          />
        </a>
      </MenuItem>
    );
  }
  const isSelected = currentPath === linkTarget;
  return (
    <MenuItem
      className={classes.menuItem}
      selected={isSelected}
      classes={{ selected: classes.selected }}
    >
      <NavLink to={linkTarget}>
        <Tooltip title={isMini && linkText} placement="right" arrow>
          <ListItemIcon
            style={{ marginRight: '8px' }}
            className={isSelected ? classes.selectedIcon : classes.icon}
          >
            {icon}
          </ListItemIcon>
        </Tooltip>
        <ListItemText
          classes={{ primary: isSelected ? classes.primarySelected : classes.primary }}
          primary={linkText}
        />
      </NavLink>
    </MenuItem>
  );
};

export default (withStyles(styles)(SidebarMenuItem));

