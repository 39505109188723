/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { ReactNode } from 'react';
import isObject from 'lodash/isObject';
import { Controller } from 'react-hook-form';
import FormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import { matchSorter, MatchSorterOptions } from 'match-sorter';
import FieldContainer from './FieldContainer';
import { FieldProps } from './FieldProps';
import useFormLabel from './useFormLabel';
import ValidationError from './ValidationError';

export interface SearchableSelectFieldProps<T> extends FieldProps<any> {
  helperText?: ReactNode;
  optional?: boolean;
  options: T[];
  sorterOptions: MatchSorterOptions;
  label?: string | undefined;
  defaultValue?: T;
  multiple?: boolean;
  labelFrom?: keyof T;
  valueFrom?: keyof T;
}

export default function SearchableSelectField<T extends Record<string, any>>({
  control,
  disabled,
  helperText,
  multiple,
  name,
  required,
  optional = false,
  rules,
  sorterOptions,
  options,
  labelFrom = 'title',
  valueFrom = 'id',
  ...props
}: SearchableSelectFieldProps<T>) {
  const label = useFormLabel(name, props.label, { optionalHint: optional });
  const filterOptions = (opts: T[], { inputValue }: { inputValue: string }) => (
    matchSorter(opts, inputValue, sorterOptions) as T[]
  );

  return (
    <FieldContainer>
      <Controller
        name={name}
        control={control}
        render={({ field: { onChange, value }, fieldState: { error } }) => (
          <FormControl error={!!error} fullWidth>
            <Autocomplete
              filterOptions={filterOptions}
              options={options}
              getOptionLabel={(option) => {
                if (isObject(option)) {
                  return option[labelFrom] as string || '';
                }
                return options.find(o => o[valueFrom] === option)?.[labelFrom] as string || '';
              }}
              renderOption={(rProps, option) => (
                <li {...rProps} key={option[valueFrom] as string}>
                  {option[labelFrom]}
                </li>
              )}
              renderInput={params => <TextField {...params} variant="outlined" label={label} />}
              isOptionEqualToValue={(option, oValue) => option?.[valueFrom] === oValue}
              onChange={(_, data) => onChange(multiple
                ? (data as T[])?.map(d => d?.[valueFrom] || d)
                : (data as T)?.[valueFrom])}
              multiple={multiple}
              filterSelectedOptions={multiple}
              value={value || (multiple ? [] : '')}
              label={label}
              disabled={disabled}
              {...props}
            />

            {error && (
              <FormHelperText>
                <ValidationError error={error} />
              </FormHelperText>
            )}

            {helperText && (<FormHelperText>{helperText}</FormHelperText>)}
          </FormControl>
        )}
        rules={rules}
      />
    </FieldContainer>
  );
}
