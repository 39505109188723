import React from 'react';
import { useTranslation } from 'react-i18next';
import PaperTableRow from './PaperTableRow';
import PaymentMethodIcon from './PaymentMethodIcon';
import PaperTable from './PaperTable';
import DownloadAsLink from './DownloadAsLink';
import SimpleStateIndicator from './SimpleStateIndicator';
import LocaleDate from './LocaleDate';

const FINISHED_ORDER_STATES = ['final', 'transferred'];

function OrderViewGeneral({ order, receipt }) {
  const { t } = useTranslation();

  if (!order) return null;

  const isFinishedOrder = FINISHED_ORDER_STATES.includes(order.state);
  const rows = [
    { key: 'id', value: order.id },
    {
      key: 'state.label',
      value: (
        <SimpleStateIndicator
          useGreen={isFinishedOrder}
          useErrorColor={!order.isTerminalAborted && !(order.paymentResult?.failureCause === 'userAborted')}
          text={t(order.stateTranslation ?? 'none')}
        />
      ),
    },
  ];

  if (order.externalCheckoutID) {
    rows.push({
      key: 'externalCheckoutID',
      value: order.externalCheckoutID,
    });
  }

  if (order.externalCheckoutReference) {
    rows.push({
      key: 'externalCheckoutReference',
      value: order.externalCheckoutReference,
    });
  }

  if (order.finalizedAt) {
    rows.push({
      key: 'finalizedAt',
      value: <LocaleDate date={order.finalizedAt} />,
    });
  }

  rows.push(
    {
      key: 'loyaltyCard',
      value: order?.customer?.loyaltyCard || '-/-',
    },
    {
      key: 'paymentMethod',
      value: order.paymentMethod ? (
        <PaymentMethodIcon
          paymentMethod={order.paymentMethod}
          project={order.project}
          paymentCardType={order.paymentCardType}
        />
      ) : (
        '-/-'
      ),
    },
  );

  if (order.receiptLink && !!receipt) {
    rows.push({
      key: 'receipt',
      value: (
        <DownloadAsLink
          blob={receipt}
          name={`receipt-${order.id}`}
          type="PDF"
        />
      ),
    });
  }

  return (
    <PaperTable headline={t('orders.general')}>
      {rows.map((row) => {
        const ordersRowKey = `orders.${row.key ?? 'none'}`;
        return (
          <PaperTableRow
            key={row.key}
            label={t(ordersRowKey)}
            value={row.value || '–'} />
        );
      })}
    </PaperTable>
  );
}

export default OrderViewGeneral;
