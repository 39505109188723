import { FieldValues } from 'react-hook-form';
import {
  defineApi,
  useProjectSpecificApiClient,
} from '../api';
import { isSuccessfulOrCustomResponse } from '../utils/statusValidator';
import { removeUndefinedObjectEntries, repairObject } from '../utils/api/repairFormArrays';

export enum TABS {
  BASE = 'base',
  APP = 'app',
  SHOP_DEVICES = 'shopDevices',
  POS_DEVICES = 'posDevices',
  SHOP_CATEGORIES = 'shopCategories',
}

export interface TabProps {
  id: TABS | string;
  title: string;
}

export enum DayOfWeek {
  MONDAY = 'Monday',
  TUESDAY = 'Tuesday',
  WEDNESDAY = 'Wednesday',
  THURSDAY = 'Thursday',
  FRIDAY = 'Friday',
  SATURDAY = 'Saturday',
  SUNDAY = 'Sunday',
}

export interface OpeningHoursSpecification {
  opens: string; // hh:mm:ss
  closes: string; // hh:mm:ss
  dayOfWeek: DayOfWeek;
}

export interface CustomerNetwork {
  ssid: string;
}

export interface Shop {
  id: string;
  name: string;
  street: string;
  zip: string;
  city: string; // this is missing in the doc
  state: string;
  countryCode: string; // Three letter representation (ISO 3166-1 alpha-3). Validated to 3 letters
  country: string;
  email: string; // Validated to be an Email.
  phone: string;
  lat: number; // -90 - 90
  lon: number; // -180 - 180
  externalId?: string;
  project: string; // project id
  external?: any; // Custom object containing additional data. Omitted if empty. Not used in RP
  visible: boolean;
  isPreLaunch: boolean;
  openingHoursSpecification: OpeningHoursSpecification[];
  services: string[];
  customerNetworks: CustomerNetwork[];
}

export const emptyShop: Shop = {
  id: '',
  name: '',
  street: '',
  zip: '',
  city: '',
  state: '',
  countryCode: '',
  country: '',
  email: '',
  phone: '',
  lat: undefined,
  lon: undefined,
  externalId: '',
  project: '',
  visible: false,
  isPreLaunch: false,
  openingHoursSpecification: [],
  services: [],
  customerNetworks: [],
} as any as Shop;

interface UpdateShopProps {
  shop: Shop;
  shopId: string | undefined;
}

function sanitizeData(data: FieldValues) {
  const repairedObject = repairObject(data);

  const { lat, lon } = repairedObject;

  let cleanedData:FieldValues = {
    ...repairedObject,
    lat: parseFloat(lat),
    lon: parseFloat(lon),
  };

  cleanedData = removeUndefinedObjectEntries(cleanedData);
  delete cleanedData.id;

  return cleanedData;
}

const useApi = defineApi({
  getShops: async (client) => {
    const { data, status } = await client({
      url: '',
      method: 'GET',
      validateStatus: statusCode => isSuccessfulOrCustomResponse(statusCode, [404]),
    });
    if (status === 404) return [] as Shop[];
    return data.shops as Shop[];
  },
  getShop: async (client, shopId: string | undefined) => {
    if (!shopId) return {} as Shop;
    const { data } = await client.get<Shop>(`/${shopId}`);
    return data;
  },
  createShop: async (client, shop: Shop) => {
    const { data } = await client.post<Shop>('', sanitizeData(shop));
    return data;
  },
  updateShop: async (client, { shop, shopId }:UpdateShopProps) => {
    if (!shopId) return;
    await client.put(`/${shopId}`, sanitizeData(shop));
  },
});

export default function useShopApi() {
  const client = useProjectSpecificApiClient({ basePath: '/shops' });
  return useApi(client);
}
