import React from 'react';
import withStyles from '@mui/styles/withStyles';
import { useLocation, useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
// @ts-ignore
import Translate from '../components/i18n/Translate';
import SidebarMenuItem from './SidebarMenuItem';
import SidebarMenuItemSub from './SidebarMenuItemSub';
// @ts-ignore
import { ReactComponent as DashboardIcon } from '../icons/team_dashboard.svg';
// @ts-ignore
import { ReactComponent as StatisticsIcon } from '../icons/monitoring.svg';
// @ts-ignore
import { ReactComponent as OrdersIcon } from '../icons/folder_open.svg';
// @ts-ignore
import { ReactComponent as ProductsIcon } from '../icons/shelves.svg';
// @ts-ignore
import { ReactComponent as ShopsIcon } from '../icons/storefront.svg';
// @ts-ignore
import { ReactComponent as AppUserIcon } from '../icons/app_user.svg';
// @ts-ignore
import { ReactComponent as ShopAccessIcon } from '../icons/passkey.svg';
// @ts-ignore
import { ReactComponent as DevicesIcon } from '../icons/devices.svg';
// @ts-ignore
import { ReactComponent as ReleasesIcon } from '../icons/deployed_code.svg';
// @ts-ignore
import { ReactComponent as CheckoutSupervisorIcon } from '../icons/security.svg';
// @ts-ignore
import { ReactComponent as UserManagemenIcon } from '../icons/manage_accounts.svg';
// @ts-ignore
import { ReactComponent as ServiceDeskIcon } from '../icons/contact_support.svg';
// @ts-ignore
import { ReactComponent as LogoutIcon } from '../icons/logout.svg';
// @ts-ignore
import { ReactComponent as ProjectConfigIcon } from '../icons/manufacturing.svg';
import { ApplicationState } from '../reducers/initialState';

const styles = ({
  sidebar: {
    boxShadow: '0 0 16px 0 rgba(0,0,0,0.18), 0 16px 16px 0 rgba(0,0,0,0.24)',
    backgroundColor: '#fff',
    // minHeight: '100vh',
    height: '100%',
    overflowY: 'auto',
    width: '250px',
    padding: 0,
    margin: 0,
    '& a': {
      textDecoration: 'none',
      color: 'inherit',
      '&:hover': {
        textDecoration: 'none',
      },
    },
  },
});

interface SidebarMenuProps {
  isMini?: boolean;
  classes: any;
}

const SidebarMenu = ({
  isMini = false,
  classes,
}: SidebarMenuProps) => {
  const supervisorHost = window.location.host.replace(/^retailer./, 'supervisor.');
  const { pathname } = useLocation();
  const { projectId } = useParams();

  const projects = useSelector<ApplicationState, any>(state => state.projects);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const project = projects.find((pr: any) => (pr.id === projectId)) || { name: 'Snabble' };
  const navigation = useSelector<ApplicationState, any>(state => state.navigation);
  const token = useSelector<ApplicationState, any>(state => state.token);

  return (
    <ul className={classes.sidebar}>
      {navigation.dashboard &&
        <SidebarMenuItem
          linkTarget={navigation.dashboard.href}
          linkText={<Translate id="dashboard.headline" />}
          icon={<DashboardIcon />}
          currentPath={pathname}
          isMini={isMini}
        />
      }
      {navigation.statistics &&
        <SidebarMenuItemSub
          currentPath={pathname}
          linkText={<Translate id="statistic.headline" />}
          icon={<StatisticsIcon />}
          isMini={isMini}
          items={[
            {
              href: `/${projectId}/statistic/revenue`,
              text: 'orderStatistic.totalRevenueNavigation',
            },
            {
              href: `/${projectId}/statistic/basket-average`,
              text: 'basketStatistic.navigation',
            },
            {
              href: `/${projectId}/statistic/recurring-visitors`,
              text: 'recurringVisitorsStatistic.navigation',
            },
            {
              href: `/${projectId}/statistic/approvals`,
              text: 'approvalDurationStatistic.navigation',
            },
            {
              href: `/${projectId}/statistic/feedback`,
              text: 'feedbackStatistic.navigation',
            },
          ]}
        />}
      {navigation.orderSubNavigation && !!navigation.orderSubNavigation.length &&
        <SidebarMenuItemSub
          currentPath={pathname}
          linkText={<Translate id="orders.headline" />}
          icon={<OrdersIcon />}
          isMini={isMini}
          items={navigation.orderSubNavigation}
        />}
      {(navigation.products || navigation.pricingCategories || navigation.taxRules) && (
        <SidebarMenuItemSub
          currentPath={pathname}
          linkText={<Translate id="product.headline" />}
          icon={<ProductsIcon />}
          isMini={isMini}
          items={[
            ...(navigation.products ? [{
              href: navigation.products.href,
              text: 'product.headlineOverview',
            }] : []),
            ...(navigation.pricingCategories ? [{
              href: navigation.pricingCategories.href,
              text: 'pricingCategory.headline',
            }] : []),
            ...(navigation.checkoutDevices ? [{
              href: `/${projectId}/checkout-devices/edit-categories`,
              text: 'checkoutDevices.headlines.updateCategories',
            }] : []),
            ...(navigation.taxRules ? [{
              href: `/${projectId}/taxRules`,
              text: 'product.permanentTax',
            }] : []),
          ]}
        />
      )}
      {navigation.shops &&
        <SidebarMenuItem
          linkTarget={navigation.shops.href}
          linkText={<Translate id="shop.headline" />}
          icon={<ShopsIcon />}
          currentPath={pathname}
          isMini={isMini}
        />
      }
      {navigation.shopAccess &&
        <SidebarMenuItemSub
          currentPath={pathname}
          linkText={<Translate id="shopAccess.headline" />}
          icon={<ShopAccessIcon />}
          isMini={isMini}
          items={[
            {
              href: `/${projectId}/shop-access/`,
              text: 'shopAccess.journal.headline',
            },
            {
              href: `/${projectId}/shop-access/block-list`,
              text: 'shopAccess.blockList.headline',
            },
          ]}
        />
      }
      {(navigation.appUserAdmin || navigation.appCustomization) &&
        <SidebarMenuItemSub
          currentPath={pathname}
          linkText={<Translate id="apps.headline" />}
          icon={<AppUserIcon />}
          isMini={isMini}
          items={[
            ...(navigation.appUserAdmin ? [{
              href: `/${projectId}/app-user-admin/`,
              text: 'appUser.headline',
            }] : []),
            ...(navigation.appCustomization ? [{
              href: `/${projectId}/app-appearance/`,
              text: 'appAppearance.headline',
            }] : []),
          ]}
        />
      }
      {navigation.checkoutDevices &&
        <SidebarMenuItemSub
          currentPath={pathname}
          linkText={<Translate id="checkoutDevices.headline" />}
          icon={<DevicesIcon />}
          isMini={isMini}
          items={[
            {
              href: `/${projectId}/checkout-devices/config`,
              text: 'checkoutDevices.headlines.globalConfig',
            },
            {
              href: `/${projectId}/checkout-devices`,
              text: 'checkoutDevices.headlines.list',
            },
          ]}
        />
      }
      {(navigation.releases || navigation.checkoutSupervisor) &&
        <SidebarMenuItemSub
          currentPath={pathname}
          linkText={<Translate id="releases.headline" />}
          icon={<ReleasesIcon />}
          isMini={isMini}
          items={[
            ...(navigation.releases ? [{
              href: `/${projectId}/releases/pos_releases`,
              text: 'releases.posHeadline',
            }] : []),
            ...(navigation.checkoutSupervisor ? [{
              href: `/${projectId}/releases/supervisor_releases`,
              text: 'releases.supervisorHeadline',
            }] : []),
          ]}
        />
      }
      {navigation.checkoutSupervisor &&
        <SidebarMenuItem
          linkTarget={`${window.location.protocol}//${supervisorHost}/${projectId}`}
          linkText="Checkout Supervisor"
          icon={<CheckoutSupervisorIcon />}
          isExternal
          isMini={isMini}
        />
      }
      {navigation.userManagement &&
        <SidebarMenuItem
          linkTarget={`/${projectId}/user-management/`}
          linkText={<Translate id="userManagement.headline" />}
          icon={<UserManagemenIcon />}
          currentPath={pathname}
          isMini={isMini}
        />
      }
      {navigation.projectConfig &&
        <SidebarMenuItem
          linkTarget={`/${projectId}/project-config/`}
          linkText={<Translate id="headlines.viewProjectConfig" />}
          icon={<ProjectConfigIcon />}
          currentPath={pathname}
          isMini={isMini}
        />
      }
      <SidebarMenuItem
        linkTarget="https://snabble.atlassian.net/servicedesk/customer/portal/1"
        linkText={<Translate id="ticket.navigation" />}
        icon={<ServiceDeskIcon />}
        isExternal
        isMini={isMini}
      />
      {token?.grants?.origin === 'account' && (
        <SidebarMenuItem
          linkTarget={`/${projectId}/user-management/change-password`}
          linkText={<Translate id="headlines.changePassword" />}
          icon={<UserManagemenIcon />}
          isMini={isMini}
        />
      )}
      <SidebarMenuItem
        linkTarget="/login?logout=true"
        linkText="Logout"
        icon={<LogoutIcon />}
        isExternal
        isMini={isMini}
      />
    </ul>
  );
};

export default withStyles(styles)(SidebarMenu);
