import React from 'react';
import { Route, Routes, Navigate, useParams } from 'react-router-dom';
import { Access } from '../access';
import RouteWrapper from '../router/RouteWrapper';
import ViewProjectConfigView from './ViewProjectConfigView';
import EditProjectConfigView from './EditProjectConfigView';

function ProjectConfigRoutes({ access }: { access?: Access }) {
  return (
    <Routes>
      {access?.read && (
        <>
          <Route path="/" element={<RouteWrapper><ViewProjectConfigView /></RouteWrapper>} />
        </>
      )}
      {access?.write && (
        <>
          <Route path="/edit" element={<RouteWrapper><EditProjectConfigView /></RouteWrapper>} />
        </>
      )}
      <Route path="/*" element={<Navigate to={`/${useParams().projectId}/404`} replace />} />
    </Routes>
  );
}

export default ProjectConfigRoutes;
