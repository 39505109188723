import React, { useCallback, useMemo, useState } from 'react';
import moment from 'moment';
import { useSelector } from 'react-redux';
import useFeedbackApi, { ratingsMetadata } from './useFeedbackApi';
import ResourceChartView from '../charts/scaffold/ResourceChartView';
// @ts-ignore
import DocumentTitle from '../components/DocumentTitle';
// @ts-ignore
import FilterWrapper from '../components/FilterWrapper';
// @ts-ignore
import FromToSelector from '../components/FromToSelector';
// @ts-ignore
import QuickRangeFilter from '../components/QuickRangeFilter';
// @ts-ignore
import Translate from '../components/i18n/Translate';
// @ts-ignore
import FilterError from '../components/FilterError';
import useChartFilter from '../charts/useChartFilter';
import FeedbackResourceWrapper from './FeedbackResourceWrapper';
import { ApplicationState } from '../reducers/initialState';
import Shop from '../shop/Shop';
import SearchableShopSelect from '../components/SearchableShopSelect';

export default function UpdateCheckoutDeviceView(props: any) {
  const api = useFeedbackApi();

  const { filter, updateFilter } = useChartFilter({
    urlQuery: props.location.search,
    autoUpdateUrl: true,
  });

  const [rangeSetByFilter, setRangeSetByFilter] = useState(true);

  const handleChangeRange = useCallback((range: { from: string, to: string }) => {
    setRangeSetByFilter(true);
    updateFilter({ range });
  }, [updateFilter]);

  const handleChangeShop = useCallback((shop) => {
    updateFilter({ shopID: shop?.id || '' });
  }, [updateFilter]);

  const handleRefresh = useCallback(() => {
    updateFilter({});
  }, [updateFilter]);

  const handleValueClick = useCallback((event: any) => {
    const time = moment(event.xLabel);
    if (time.isValid()) {
      setRangeSetByFilter(false);
      updateFilter({
        range: {
          from: time.startOf('day').toISOString(true),
          to: time.endOf('day').toISOString(true),
        },
      });
    }
  }, [updateFilter]);

  const shops = useSelector<ApplicationState, Shop[]>(state => state.shops);
  const shopsMap = useMemo(() => new Map(shops.map((obj: any) => [obj.id, obj])), [shops]);

  return (
    <React.Fragment>
      <DocumentTitle translationID="feedbackStatistic.headline" />
      <FilterWrapper
        headline={<Translate id="feedbackStatistic.headline" />}
        handleRefresh={handleRefresh}
        error={
          <FilterError id={filter.error} data={filter.errorData} />
        }
        topBarChildren={<></>}
      >
        <FromToSelector
          range={filter.range}
          hasError={undefined}
          onChange={handleChangeRange}
          emitFilled
        />
        <QuickRangeFilter
          range={filter.range}
          onChange={handleChangeRange}
        />
        <SearchableShopSelect
          onChange={handleChangeShop}
          defaultShopId={filter.shopID}
          showLabel
        />
      </FilterWrapper>
      <ResourceChartView
        Chart={FeedbackResourceWrapper}
        ChartProps={{
          range: filter.range,
          ratingsMetadata,
          stackedOnInit: rangeSetByFilter,
          onValueClick: handleValueClick,
          shopsMap,
        }}
        fetch={api.getFeedback}
        filter={filter}
      />
    </React.Fragment>
  );
}
