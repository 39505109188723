import { Shop } from '../shop/useShopApi';

export enum CheckoutDeviceType {
  SCO = 'sco',
  GATEKEEPER = 'gatekeeper',
  POS = 'pos',
  'SCO-NG' = 'sco-ng',
}

export default interface CheckoutDevice {
  aggregateStatus?: string;
  description?: string;
  endpoint?: string;
  externalID?: string;
  id?: string;
  lastSeenAt?: string;
  transactionInfo?: {
    dailyFailedTransactionCount: number;
    dailyOverallTransactionCount: number;
    dailySuccessfulTransactionCount: number;
    failedTransactionCount: number;
    overallTransactionCount: number;
    successfulTransactionCount: number;
  };
  links?: any;
  name?: string;
  project?: string;
  secret?: string;
  shop: string;
  type: CheckoutDeviceType;
  createdAt?: string;
}

export interface CheckoutDeviceWithShop extends Omit<CheckoutDevice, 'shop'> {
  shop: Shop | undefined;
  shopName?: string;
}

export enum Status {
  UP = 'UP',
  DOWN = 'DOWN',
  DEGRADED = 'DEGRADED',
}

interface Error {
  type: string; // Type
  message?: string; // Message
}

interface ShopDeviceStatus {
  id: string; // ID
  kind: CheckoutDeviceType; // Kind
  deviceName: string; // DeviceName
  status: Status; // Status
  errors?: Error[]; // Errors
  systemInfo?: Record<string, any>; // SystemInfo
  lastSeenAt: string; // LastSeenAt
}
export interface ShopStatus {
  status: Status;
  devices: ShopDeviceStatus[];
}

