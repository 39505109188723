import { predefinedRanges } from '../ranges';
import Shop from '../shop/Shop';

const initialState = {
  token: {
    raw: '',
    grants: {},
  },
  authorized: null,
  restrictedProject: false,
  projects: [],
  navigation: {},
  selectedProjectId: null,
  fetchingProjects: false,
  projectCurrency: null,
  shops: [],
  shop: {},
  selectedShop: null,
  fetchingShops: false,
  fetchingInitialShops: false,
  fetchingShop: false,
  fetchingProducts: false,
  fetchingProductsError: false,
  products: [],
  fetchingProduct: false,
  product: {},
  orders: {
    list: [],
    pagination: {},
  },
  order: {},
  ordersExport: [],
  ordersExportError: null,
  fetchingOrdersExport: false,
  lineItemExport: [],
  fetchingLineItemExport: false,
  fetchingOrder: false,
  fetchingOrderStatistic: false,
  orderStatistic: null,
  basketAverageStatistic: null,
  fetchingBasketAverageStatistic: false,
  recurringVisitorsStatistic: null,
  fetchingRecurringVisitorsStatistic: false,
  codeTemplates: {},
  range: predefinedRanges.last14Days,
  shopFilter: null,
  withCustomerCardFilter: '',
  paymentMethodsFilter: [],
  closings: {
    isLoading: false,
    details: null,
    editStatus: '',
    serverError: null,
    pagination: {},
    list: [],
    schedules: [],
    schedule: null,
  },
  checkoutDevices: {
    list: {},
    isFetchingList: false,
    status: {},
    device: null,
    isFetchingDevice: false,
    hasTerminalIssue: false,
  },
  approvalDurationStatistic: null,
  fetchingApprovalDurationStatistic: false,
  genericFilter: {},
};

// TODO: We should explicitly provide a store schema rather than inferring from
// the initial state
export type InitialApplicationState = typeof initialState;

export interface ApplicationState extends Omit<InitialApplicationState, 'shops'> {
  shops: Shop[];
}

export default initialState;
