import React, { useMemo } from 'react';
import EditIcon from '@mui/icons-material/Edit';
import VisibilityIcon from '@mui/icons-material/Visibility';
import SettingsIcon from '@mui/icons-material/Settings';
import TableCell from '@mui/material/TableCell';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import Tooltip from '@mui/material/Tooltip';
import Stack from '@mui/material/Stack';
import IconButton from '@mui/material/IconButton';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { SortableTable, ColumnsProp } from '../SortableTable';
// @ts-ignore
import Translate from '../components/i18n/Translate';
// @ts-ignore
import useResourceTranslator from '../resource/useResourceTranslator';
import useProjectNavigate from '../useProjectNavigate';
import { Status } from './CheckoutDevice';
import { DeviceStatus } from './CheckoutDeviceHelper';
import CheckoutDevicesStatusIndicator from './CheckoutDevicesStatusIndicator';
import { buildShopLabel } from '../shop';
// @ts-ignore
import { dateFormat } from '../components/LocaleDate';
import ProjectContentLink from '../components/ProjectContentLink';

export interface CheckoutDeviceListTableProps {
  values: any;
  writePermission: boolean;
  onDeviceQuickEdit?: (device: any) => void;
}

export default function CheckoutDeviceListTable({
  values,
  writePermission = false,
  onDeviceQuickEdit,
}: CheckoutDeviceListTableProps) {
  const t = useResourceTranslator();
  const navigate = useProjectNavigate();
  const { i18n } = useTranslation();

  const headCells = useMemo<ColumnsProp>(() => [
    {
      key: 'id',
      disablePadding: false,
      displayName: t('checkoutDevices.shortId'),
    },
    {
      key: 'externalID',
      disablePadding: false,
      displayName: t('checkoutDevices.externalID'),
    },
    {
      key: 'name',
      disablePadding: false,
      displayName: t('checkoutDevices.name'),
    },
    {
      key: 'shopName',
      disablePadding: false,
      displayName: t('checkoutDevices.shop'),
    },
    {
      key: 'type',
      disablePadding: false,
      displayName: t('checkoutDevices.type'),
    },
    {
      key: 'status',
      disablePadding: false,
      displayName: t('checkoutDevices.statusIndicator'),
    },
    {
      type: 'padding',
      disablePadding: false,
    },
  ], [t]);

  const TableRowEntries = useMemo(() => ({ rowData }: any) => (
    <>
      <TableCell
        align="left"
        style={{
          cursor: 'pointer',
          textDecoration: 'underline',
        }}
      >
        <Tooltip title={rowData.id} placement="top" arrow>
          <ProjectContentLink
            to={`/checkout-devices/id/${rowData.id}`}
            onClick={(e) => { e.preventDefault(); onDeviceQuickEdit?.(rowData); }}
          >
            <span>
              {rowData.id.slice(0, 4)}
            </span>
          </ProjectContentLink>
        </Tooltip>
      </TableCell>
      <TableCell>
        {rowData.externalID}
      </TableCell>
      <TableCell>
        {rowData.name}
      </TableCell>
      <TableCell
        onClick={() => { navigate(`/shops/${rowData.shop.id}`); }}
        style={{
          cursor: 'pointer',
          textDecoration: 'underline',
        }}
      >
        <Tooltip title={buildShopLabel(rowData.shop)} placement="top" arrow>
          {rowData.shop?.name}
        </Tooltip>
      </TableCell>
      <TableCell align="left">
        {t(`checkoutDevices.types.${rowData.type}`)}
      </TableCell>
      <TableCell>
        <Tooltip
          title={t('checkoutDevices.lastSeenAt', {
            timestamp: rowData.lastSeenAt ? (moment(rowData.lastSeenAt).format(dateFormat(i18n.language))) : '-/-',
          })}
          placement="top"
          arrow
        >
          <div>
            <CheckoutDevicesStatusIndicator
              status={rowData.aggregateStatus === Status.UP
                ? DeviceStatus.Online
                : DeviceStatus.Offline
              }
            />
          </div>
        </Tooltip>
      </TableCell>
      <TableCell padding="checkbox" align="right">
        <Stack direction="row">
          <Tooltip title="viewDevice" placement="top" arrow>
            <ProjectContentLink to={`/checkout-devices/id/${rowData.id}`}>
              <IconButton size="small">
                <VisibilityIcon />
              </IconButton>
            </ProjectContentLink>
          </Tooltip>
          {!!writePermission && (
            <>
              <Tooltip title="editDevice" placement="top" arrow>
                <ProjectContentLink to={`/checkout-devices/${rowData.id}/edit`}>
                  <IconButton size="small">
                    <EditIcon />
                  </IconButton>
                </ProjectContentLink>
              </Tooltip>
              <Tooltip title="deviceConfig" placement="top" arrow>
                <ProjectContentLink to={`/checkout-devices/${rowData.id}/config`}>
                  <IconButton size="small">
                    <SettingsIcon />
                  </IconButton>
                </ProjectContentLink>
              </Tooltip>
            </>
          )}
        </Stack>
      </TableCell>
    </>
  ), [i18n.language, navigate, onDeviceQuickEdit, t, writePermission]);

  const table = (
    (values?.length > 0) ?
      (<SortableTable
        data={values || []}
        columnsMetadata={headCells}
        title=""
        filterPlaceholder={t('checkoutDevices.table.filterPlaceholder')}
        labelRowsPerPage={t('checkoutDevices.table.labelRowsPerPage')}
        CustomRowContent={TableRowEntries}
        verticalToolbarPadding={4}
        rowsPerPageOptions={[25, 50, 100, 500]}
        rowsPerPageDefault={50}
      />)
      :
      (
        <div style={{ padding: '2rem' }}>
          <Typography><Translate id="checkoutDevices.table.empty" /></Typography>
        </div>
      )
  );

  return (
    <Paper elevation={4}>
      {table}
    </Paper>
  );
}
