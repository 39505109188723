import React from 'react';
import { useTranslation } from 'react-i18next';
import { Order, State as OrderState, PaymentState } from './orderTypes';
import AdvancedStateIndicator, { State } from '../components/AdvancedStateIndicator';
import TextWithSubText from '../components/TextWithSubText';

export interface OrderStateIndicatorProps {
  paymentState: Order['paymentState'];
  state: Order['state'];
  paymentResult: Order['paymentResult'];
}

export default function OrderStateIndicator({
  paymentState,
  state,
  paymentResult,
}: OrderStateIndicatorProps) {
  const { t } = useTranslation();

  const computedPaymentState = {
    [PaymentState.Successful]: State.Success,
    [PaymentState.Transferred]: State.Success,
    [PaymentState.Pending]: State.Warning,
    [PaymentState.Failed]: State.Error,
  }[paymentState] || State.Warning;

  const computedOrderState = {
    [OrderState.Final]: State.Success,
    [OrderState.Transferred]: State.Success,
    [OrderState.UserAborted]: State.Warning,
    [OrderState.SystemAborted]: State.Warning,
    [OrderState.PreconditionsNotMet]: State.Warning,
    [OrderState.PaymentFailed]: State.Error,
  }[state] || State.Warning;

  // NOTE the computed state is based on the payment state and the order state to ensure it working
  // on all environments. On non-production environments the state could be e.d. paymentFailed
  // while the paymentState is still successful.
  let computedState;
  if (computedOrderState === State.Error || computedPaymentState === State.Error) {
    computedState = State.Error;
  } else if (computedOrderState === State.Warning || computedPaymentState === State.Warning) {
    computedState = State.Warning;
  } else {
    computedState = State.Success;
  }

  const computedLabel = t(`orders.state.${state || paymentState}`, { defaultValue: state || paymentState });
  const computedTooltip = paymentResult?.failureCause ? t(`orders.state.${paymentResult?.failureCause}`) : '';

  return (
    <AdvancedStateIndicator
      state={computedState}
      label={(
        <TextWithSubText
          text={computedLabel}
          subText={computedTooltip}
        />
      )}
      variant="outlined"
      sx={{ height: 'auto' }}
    />
  );
}
