import React from 'react';
import { Link } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import Translate from '../components/i18n/Translate';
import SnabbleLinearProgress from '../components/SnabbleLinearProgress';
import DocumentTitle from '../components/DocumentTitle';
import NoResult from '../components/NoResult';
import PaperTable from '../components/PaperTable';
import PaperTableRow from '../components/PaperTableRow';
import LocaleDate from '../components/LocaleDate';
import CheckoutDevicesStatusIndicator from './CheckoutDevicesStatusIndicator';
import ResourceTranslate from '../resource/ResourceTranslate';
import ButtonHeadline from '../headline/ButtonHeadline';
import ContentLink from '../components/ContentLink';
import { WithResourceAccess } from '../access/resource';
import {
  buildOSVersionString,
  buildTerminalTypesList,
  buildTerminalVersionsList,
  buildTransactionInfoString,
  buildTimestampString,
  DeviceStatus,
} from './CheckoutDeviceHelper';

class CheckoutDeviceView extends React.Component {
  componentDidMount() {
    const { id } = this.props;
    this.props.fetchCheckoutDevice(id);
  }

  componentDidUpdate(prevProps) {
    if (this.props.canFetchStatus && !prevProps.canFetchStatus) {
      this.props.fetchCheckoutDevicesStatus(this.props.checkoutDevice.shop);
    }
  }

  render() {
    const {
      checkoutDevice, isLoading, id, status, projectId, t,
    } = this.props;

    return (
      <React.Fragment>
        <SnabbleLinearProgress show={isLoading} />
        <DocumentTitle translationID="headlines.show" />

        <ButtonHeadline
          headline={<ResourceTranslate id="headlines.show" />}
        >
          <WithResourceAccess privilege="write">
            <Button
              variant="contained"
              color="secondary"
              component={Link}
              to={`/${projectId}/checkout-devices/${id}/config`}
            >
              <ResourceTranslate id="actions.config" />
            </Button>
            <Button
              variant="contained"
              color="primary"
              component={Link}
              to={`/${projectId}/checkout-devices/${id}/edit`}
            >
              <ResourceTranslate id="actions.edit" />
            </Button>
          </WithResourceAccess>
        </ButtonHeadline>

        {!checkoutDevice &&
          <NoResult
            text={<Translate id="checkoutDevices.singleNotFound" data={{ id }} />}
          />
        }

        {!!checkoutDevice &&
          <PaperTable headline={<Translate id="checkoutDevices.headlineSingle" />}>
            <PaperTableRow label={<Translate id="checkoutDevices.id" />} value={checkoutDevice.id} />
            <PaperTableRow label={<Translate id="checkoutDevices.type" />} value={<Translate id={`checkoutDevices.types.${checkoutDevice.type}`} defaultValue={checkoutDevice.type} />} />
            <PaperTableRow label={<Translate id="checkoutDevices.externalID" />} value={checkoutDevice.externalID} />
            <PaperTableRow label={<Translate id="checkoutDevices.name" />} value={checkoutDevice.name} />
            <PaperTableRow label={<Translate id="checkoutDevices.description" />} value={checkoutDevice.description} />
            <PaperTableRow
              label={<Translate id="checkoutDevices.shop" />}
              value={checkoutDevice?.shopInformation?.id ? (
                <ContentLink to={`/${checkoutDevice.project}/shops/${checkoutDevice.shopInformation.id}`}>
                  {checkoutDevice.shopLabel}
                </ContentLink>
              ) : (
                checkoutDevice.shopLabel
              )}
            />
          </PaperTable>
        }

        {!!status &&
          <PaperTable headline={<Translate id="checkoutDevices.statusIndicator" />}>
            <PaperTableRow
              label={<Translate id="checkoutDevices.statusIndicator" />}
              value={
                <CheckoutDevicesStatusIndicator status={status.status} />
              }
            />
            {status.uiStatus !== DeviceStatus.NA &&
              <PaperTableRow
                label={<Translate id="checkoutDevices.software" />}
                value={
                  <CheckoutDevicesStatusIndicator status={status.uiStatus} />
                }
              />
            }
            {status.serviceStatus !== DeviceStatus.NA &&
              <PaperTableRow
                label={<Translate id="checkoutDevices.service" />}
                value={
                  <CheckoutDevicesStatusIndicator status={status.serviceStatus} />
                }
              />
            }
            {status.posStatus !== DeviceStatus.NA &&
              <PaperTableRow
                label={<Translate id="checkoutDevices.pos" />}
                value={
                  <CheckoutDevicesStatusIndicator status={status.posStatus} />
                }
              />
            }
            {status.terminalStatus !== DeviceStatus.NA &&
              <PaperTableRow
                label={<Translate id="checkoutDevices.terminal" />}
                value={
                  <CheckoutDevicesStatusIndicator status={status.terminalStatus} />
                }
              />
            }
            {status.printerStatus !== DeviceStatus.NA &&
              <PaperTableRow
                label={<Translate id="checkoutDevices.printer" />}
                value={
                  <Stack direction="row" gap={0.5}>
                    <CheckoutDevicesStatusIndicator status={status.printerStatus} />
                    {status.messages?.findIndex(c => c.type === 'printer_error') >= 0 && (
                      <span>
                        (
                        {
                          status.messages
                            .filter(c => c.type === 'printer_error')
                            .map(c => t(`checkoutDevices.printerError.${c.message}`, { defaultValue: c.message }))
                            .join(', ')
                        }
                        )
                      </span>
                    )}
                  </Stack>
                }
              />
            }
            <PaperTableRow
              label={<Translate id="checkoutDevices.lastSeen" />}
              value={status?.lastSeenAt ? buildTimestampString(status.lastSeenAt) : '-/-'}
            />
          </PaperTable>
        }

        {!!status && !!status.systemInfo?.transactionInfo &&
          <PaperTable headline={<Translate id="checkoutDevices.transactionInfo" />}>
            <PaperTableRow
              label={<Translate id="checkoutDevices.transactionDaily" />}
              value={buildTransactionInfoString({
                successful: status.systemInfo.transactionInfo.dailySuccessfulTransactionCount,
                failed: status.systemInfo.transactionInfo.dailyFailedTransactionCount,
                overall: status.systemInfo.transactionInfo.dailyOverallTransactionCount,
              })}
            />
            <PaperTableRow
              label={<Translate id="checkoutDevices.transaction" />}
              value={buildTransactionInfoString({
                successful: status.systemInfo.transactionInfo.successfulTransactionCount,
                failed: status.systemInfo.transactionInfo.failedTransactionCount,
                overall: status.systemInfo.transactionInfo.overallTransactionCount,
              })}
            />
          </PaperTable>
        }

        {!!status && !!status.systemInfo &&
          <PaperTable headline={<Translate id="checkoutDevices.systemInfo" />}>
            {!!status.systemInfo.os &&
              <React.Fragment>
                <PaperTableRow label={<Translate id="checkoutDevices.osName" />} value={buildOSVersionString(status.systemInfo.os)} />
                <PaperTableRow label={<Translate id="checkoutDevices.osBuild" />} value={status.systemInfo.os.build} />
              </React.Fragment>
            }
            {!!status.systemInfo.host &&
              <PaperTableRow label={<Translate id="checkoutDevices.bootTime" />} value={<LocaleDate date={status.systemInfo.host.bootTime} />} />
            }
            {!!status.systemInfo.process &&
              <React.Fragment>
                <PaperTableRow label={<Translate id="checkoutDevices.processVersion" />} value={status.systemInfo.process.version} />
                <PaperTableRow label={<Translate id="checkoutDevices.processStart" />} value={<LocaleDate date={status.systemInfo.process.startTime} />} />
              </React.Fragment>
            }
            {!!status.systemInfo.supportedTerminalTypes &&
              <PaperTableRow
                label={<Translate id="checkoutDevices.supportedTerminalTypes" />}
                value={buildTerminalTypesList(status.systemInfo.supportedTerminalTypes)}
              />
            }
            {!!status.systemInfo.selectedTerminalType &&
              <PaperTableRow label={<Translate id="checkoutDevices.selectedTerminalType" />} value={status.systemInfo.selectedTerminalType} />
            }
            {!!status.systemInfo.installedTerminalVersions &&
              <PaperTableRow
                label={<Translate id="checkoutDevices.installedTerminalVersions" />}
                value={buildTerminalVersionsList(status.systemInfo.installedTerminalVersions)}
              />
            }
          </PaperTable>
        }

      </React.Fragment>
    );
  }
}

export default withTranslation()(CheckoutDeviceView);
