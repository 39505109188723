import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { PasswordChoicePolicy } from './useUserManagementApi';

export enum ValidationPolicyTypes {
  ANY = 'any',
  EMAIL = 'email',
  NUMERIC = 'numeric',
  ALPHANUMERIC = 'alphaNumeric',
}

export interface ValidationPolicy {
  type?: ValidationPolicyTypes;
  minLength?: number;
  maxLength?: number;
}

export interface ValidationRules {
  pattern?: RegExp;
  minLength?: number;
  maxLength?: number;
  hint?: string;
}

export interface PasswordChoiceRule {
  email: {
    enabled: boolean;
    required: boolean;
  };
  password: {
    enabled: boolean;
    required: boolean;
  };
}

export default function useValidationPolicyParser() {
  const { t } = useTranslation();

  const mapPasswordRules = useCallback((policy: PasswordChoicePolicy | undefined) => {
    if (!policy) {
      return {
        email: {
          enabled: true,
          required: false,
        },
        password: {
          enabled: true,
          required: false,
        },
      };
    }

    switch (policy) {
      case PasswordChoicePolicy.RESET_VIA_EMAIL:
        return {
          email: {
            enabled: true,
            required: true,
          },
          password: {
            enabled: false,
            required: false,
          },
        };
      case PasswordChoicePolicy.SET_BY_CREATOR:
        return {
          email: {
            enabled: false,
            required: false,
          },
          password: {
            enabled: true,
            required: true,
          },
        };
      case PasswordChoicePolicy.ANY:
      default:
        return {
          email: {
            enabled: true,
            required: false,
          },
          password: {
            enabled: true,
            required: false,
          },
        };
    }
  }, []);

  const mapValidationPolicyToRules = useCallback((policy?: ValidationPolicy) => {
    if (!policy) return {};

    let pattern;
    let typeHint;

    switch (policy.type) {
      case ValidationPolicyTypes.ANY:
        typeHint = 'Any characters';
        break;
      case ValidationPolicyTypes.EMAIL:
      // NOTE this is jus a very simple e-mail regex since it is only here to assist the user.
      // The full check is done in the backend.
        pattern = /^[a-z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-z0-9-]+\.[a-z0-9-]+$/;
        typeHint = t('form.validation.email');
        break;
      case ValidationPolicyTypes.NUMERIC:
        pattern = /^\d+$/;
        typeHint = t('form.validation.numeric');
        break;
      case ValidationPolicyTypes.ALPHANUMERIC:
        pattern = /^[a-zA-Z\d]+$/;
        typeHint = t('form.validation.alphanumeric');
        break;
      default:
    }

    // TODO refactor and translate when it is final how the hints should look
    // (if this becomes a hook it can directly access the translations)
    let lengthHint;
    if (!!policy.minLength && !!policy.maxLength) {
      if (policy.minLength === policy.maxLength) {
        lengthHint = t('form.validation.length.exact', { length: policy.minLength });
      } else {
        lengthHint = t('form.validation.length.between', { min: policy.minLength, max: policy.maxLength });
      }
    } else if (policy.minLength) {
      lengthHint = t('form.validation.length.min', { min: policy.minLength });
    } else if (policy.maxLength) {
      lengthHint = t('form.validation.length.max', { max: policy.maxLength });
    }

    let hint;
    if (typeHint && lengthHint) {
      hint = `${typeHint} (${lengthHint})`;
    } else {
      hint = typeHint || lengthHint;
    }

    const { minLength, maxLength } = policy;

    return {
      ...(pattern ? { pattern } : {}),
      ...(minLength ? { minLength } : {}),
      ...(maxLength ? { maxLength } : {}),
      ...(hint ? { hint } : {}),
    };
  }, [t]);

  return { mapPasswordRules, mapValidationPolicyToRules };
}
