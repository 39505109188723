import React, { ReactNode, forwardRef } from 'react';
import Stack from '@mui/material/Stack';
import {
  CustomFormProps,
  DefaultFormActions,
  Fieldset,
  Form,
  useEnhancedForm,
} from '../form';
import { TextField } from '../form/input';
import { useResourceTranslator } from '../resource';
import { ShopPosConfig } from './useShopPosConfigApi';
import ArrayWrapper from '../form/wrapper/ArrayWrapper';
import { CustomFormRef } from '../form/CustomFormProps';
import { useForceSubmitDialog } from '../shop/useForceSubmitDialog';

const DEFAULT_VALUES: ShopPosConfig = {
  printers: [],
};

export interface ShopPosConfigFormProps extends CustomFormProps<ShopPosConfig> {
  additionalButtons?: ReactNode;
}

const ShopPosConfigForm = forwardRef<CustomFormRef, ShopPosConfigFormProps>(({
  defaultValues = DEFAULT_VALUES,
  errors = {},
  onSubmit,
  additionalButtons,
}, ref) => {
  const t = useResourceTranslator();

  const {
    handleSubmit,
    control,
    formState,
    getValues,
    reset,
  } = useEnhancedForm({ defaultValues, errors });

  const { ConfirmationDialog } = useForceSubmitDialog({ formState, ref });

  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <Fieldset category="printers">
        <ArrayWrapper
          control={control}
          name="printers"
          defaultValue={{ name: '', address: '', linePitch: '55' }}
          render={index => (
            <Stack spacing={3}>
              <TextField
                control={control}
                name={`printers[${index}].name`}
                required
              />
              <TextField
                control={control}
                name={`printers[${index}].address`}
                required
                helperText={t('form.labels.printers.addressHint')}
              />
              <TextField
                control={control}
                name={`printers[${index}].linePitch`}
                required
                helperText={t('form.labels.printers.linePitchHint')}
              />
            </Stack>
          )}
        />
      </Fieldset>

      <ConfirmationDialog />
      <DefaultFormActions
        formState={formState}
        getValues={getValues}
        reset={reset}
        additionalButtons={additionalButtons}
      />
    </Form>
  );
});

export default ShopPosConfigForm;
