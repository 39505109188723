import React, { useCallback, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import Typography from '@mui/material/Typography';
import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';
import Paper from '@mui/material/Paper';
import LockIcon from '@mui/icons-material/Lock';
import BlockIcon from '@mui/icons-material/Block';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import TableCell from '@mui/material/TableCell';
import Stack from '@mui/material/Stack';
import { formatCell } from '../SortableTable';
import Translate from '../components/i18n/Translate';
// @ts-ignore
import useProjectNavigate from '../useProjectNavigate';
// @ts-ignore
import ColoredTableRow from '../components/ColoredTableRow';
// @ts-ignore
import PaperTable from '../components/PaperTable';
import { ApplicationState } from '../reducers/initialState';
import Shop from '../shop/Shop';
import shopLabel from '../shop';
import AdvancedStateIndicator, { State } from '../components/AdvancedStateIndicator';

export interface JournalTableProps {
  values: any;
  onFocusUser: (credentialsValue: string) => void;
}

export default function JournalTable({
  values,
  onFocusUser,
}: JournalTableProps) {
  const { t } = useTranslation();
  const navigate = useProjectNavigate();
  const shops = useSelector<ApplicationState, Shop[]>(state => state.shops);

  const copyUserIdToClipboard = (value: any) => {
    navigator.clipboard.writeText(value);
  };

  const handleBlockUser = useCallback(({ credentialsType, credentialsValue, isBlocked }) => {
    if (!isBlocked) {
      navigate('/shop-access/block-list/new', {
        state: {
          credentialsType,
          credentialsValue,
        },
      });
    } else {
      navigate('/shop-access/block-list', {
        state: {
          filter: credentialsValue,
        },
      });
    }
  }, [navigate]);

  const TableRowEntries = useMemo(() => ({ rowData }: any) => (
    <ColoredTableRow>
      <TableCell align="left">
        {t(`shopAccess.credentialsTypes.${rowData.credentialsType}`)}
      </TableCell>
      <TableCell
        align="left"
        onClick={() => { onFocusUser(rowData.credentialsValue); }}
        style={{
          cursor: 'pointer',
          textDecoration: 'underline',
          ...(rowData.isBlocked ? { textDecoration: 'underline line-through' } : {}),
        }}
      >
        <Stack direction="row">
          {rowData.isBlocked && (
            <Tooltip title={rowData.blockReason || ''} placement="top" arrow>
              <LockIcon fontSize="small" />
            </Tooltip>
          )}
          {rowData.credentialsValue}
        </Stack>
      </TableCell>
      <TableCell align="left">
        <AdvancedStateIndicator
          state={rowData.accessGranted ? State.Success : State.Error}
          label={{
            [State.Success]: t('shopAccess.journal.accessGrantedFilter.granted'),
            [State.Error]: t(`shopAccess.denialReasons.${rowData.denialReason}`),
          }}
        />
      </TableCell>
      <TableCell align="left">
        {formatCell(rowData.attemptTime, 'datetime')}
      </TableCell>
      <TableCell align="left">
        { shopLabel(shops.find(shop => shop.id === rowData.shopID)) }
      </TableCell>
      <TableCell padding="checkbox" align="right">
        <div style={{ display: 'flex', flexGrow: 1 }} >
          <Tooltip title={t('tooltip.copyUserId')} placement="top" arrow>
            <IconButton size="small" onClick={() => { copyUserIdToClipboard(rowData.credentialsValue); }}>
              <ContentCopyIcon />
            </IconButton>
          </Tooltip>
          {rowData.isBlocked ? (
            <Tooltip title={t('tooltip.openInBlocklist')} placement="top" arrow>
              <IconButton size="small" onClick={() => { handleBlockUser(rowData); }}>
                <OpenInNewIcon />
              </IconButton>
            </Tooltip>
          ) : (
            <Tooltip title={t('tooltip.blockUser')} placement="top" arrow>
              <IconButton size="small" onClick={() => { handleBlockUser(rowData); }}>
                <BlockIcon />
              </IconButton>
            </Tooltip>
          )}
        </div>
      </TableCell>
    </ColoredTableRow>
  ), [handleBlockUser, onFocusUser, shops, t]);

  const headCells = useMemo<string[]>(() => [
    t('shopAccess.journal.credentialsType'),
    t('shopAccess.journal.credentialsValue'),
    t('shopAccess.journal.accessGranted'),
    t('shopAccess.journal.attemptTime'),
    t('shopAccess.journal.shopID'),
    '',
  ], [t]);

  const table = (
    (values?.list?.length > 0) ?
      (
        <PaperTable tableHeader={headCells}>
          {(values.list || []).map((order: any) => (
            <TableRowEntries rowData={order} />
          ))}
        </PaperTable>
      )
      :
      (
        <div style={{ padding: '2rem' }}>
          <Typography><Translate id="shopAccess.journal.empty" /></Typography>
        </div>
      )
  );

  return (
    <Paper elevation={4} >
      {table}
    </Paper>
  );
}
