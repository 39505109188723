import React, { ReactNode, useCallback, useEffect, useRef, useState } from 'react';
import { NavLink } from 'react-router-dom';
import withStyles from '@mui/styles/withStyles';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import MenuItem from '@mui/material/MenuItem';
import Menu from '@mui/material/Menu';
import KeyboardArrowDownOutlined from '@mui/icons-material/KeyboardArrowDownOutlined';
import KeyboardArrowUpOutlined from '@mui/icons-material/KeyboardArrowUpOutlined';
import KeyboardArrowRightOutlined from '@mui/icons-material/KeyboardArrowRightOutlined';
import Tooltip from '@mui/material/Tooltip';
import Divider from '@mui/material/Divider';
import Box from '@mui/material/Box';
import Translate from '../components/i18n/Translate';

const styles = (theme: any) => ({
  menuItem: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),

    [theme.breakpoints.down(700)]: {
      paddingTop: theme.spacing(1.5),
      paddingBottom: theme.spacing(1.5),
    },
  },
  subMenu: {
    backgroundColor: 'rgba(0,0,0,0.04)',
    padding: 0,
    height: 'auto',
  },
  subMenuMini: {
    padding: 0,
    height: 'auto',
  },
  subItem: {
    color: 'rgba(0,0,0,0.87)',
    width: '100%',
    display: 'block',
    padding: theme.spacing(2),
    paddingLeft: theme.spacing(7),
    whiteSpace: 'initial',

    [theme.breakpoints.down(700)]: {
      padding: theme.spacing(1.5),
      paddingLeft: theme.spacing(5),
    },
  },
  subItemMini: {
    color: 'rgba(0,0,0,0.87)',
    width: '100%',
    display: 'block',
    padding: theme.spacing(2),
    whiteSpace: 'initial',
    textDecoration: 'none',
  },
  primarySelected: {
    color: theme.palette.primary.main,
    fontWeight: 'bold',
  },
  subItemSelected: {
    // NOTE '& a' hast the same specificity here as in the classes of the SidebarMenu component
    // this lead to the text sometimes not being colored correctly and sometimes not being colored.
    // By adding these pseudo classes we can ensure that this style overrides the one in the
    // SidebarMenu component through its specificity.
    '& a:not(:empty)': {
      color: `${theme.palette.primary.main}`,
      backgroundColor: 'rgba(0,0,0,0.04)',
    },
  },
  subItemMiniSelected: {
    '& a:not(:empty)': {
      color: `${theme.palette.primary.main}`,
    },
  },
  iconSelected: {
    '& svg': {
      '& path': {
        fill: theme.palette.primary.main,
      },
    },
  },
  icon: {
    marginRight: '8px',
    color: 'rgba(0,0,0,0.87)',
  },
  popoutHeader: {
    paddingLeft: '16px',
    paddingBlock: '8px',
    '& span': {
      fontWeight: 'bold',
    },
  },
  popoutIndicator: {
    position: 'absolute',
    left: '32px',
    height: '16px',
  },
  popoutIndicatorSelected: {
    '& path': {
      fill: theme.palette.primary.main,
    },
  },
});

const isOneOfMineActive = (items: any, currentPath: any) => (
  !!items.find((item: any) => item.href === currentPath)
);

interface SidebarMenuItemSubItemProps {
  text: string;
  href: string;
}

export interface SidebarMenuItemSubProps {
  currentPath: string;
  linkText: string;
  icon: ReactNode;
  items: SidebarMenuItemSubItemProps[];
  isMini?: boolean;
  classes: any;
}

const SidebarMenuItemSub = ({
  currentPath,
  linkText,
  icon,
  items,
  isMini = false,
  classes,
}: SidebarMenuItemSubProps) => {
  const menuAnchor = useRef(null);

  const [showPopoutMenu, setShowPopoutMenu] = useState(false);
  const [isExtended, setIsExtended] = useState(isOneOfMineActive(items, currentPath));
  const [parentSelected, setParentSelected] = useState(false);

  const handleClick = useCallback(() => {
    if (isMini) {
      setShowPopoutMenu(true);
    } else {
      setIsExtended(!isExtended);
    }
  }, [isExtended, isMini]);

  useEffect(() => {
    const isChildActive = isOneOfMineActive(items, currentPath);
    setShowPopoutMenu(false); // Close popout menu when navigating
    setParentSelected(isChildActive); // Set parent icon selected if child is active
    // Expand parent if child is active (only in extended mode)
    if (!isMini && isChildActive) setIsExtended(true);
  }, [currentPath, isMini, items]);

  return (
    <>
      <MenuItem
        onClick={handleClick}
        className={classes.menuItem}
      >
        <Tooltip title={isMini && linkText} placement="right" arrow>
          <Box>
            <ListItemIcon
              className={`${classes.icon} ${parentSelected ? classes.iconSelected : ''}`}
              ref={menuAnchor}
            >
              {icon}
            </ListItemIcon>
            {isMini && (
              <KeyboardArrowRightOutlined
                className={`${classes.popoutIndicator} ${parentSelected ? classes.popoutIndicatorSelected : ''}`}
              />
            )}
          </Box>
        </Tooltip>
        <ListItemText
          classes={{ primary: parentSelected ? classes.primarySelected : classes.primary }}
          primary={linkText}
        />
        {isExtended ? <KeyboardArrowUpOutlined /> : <KeyboardArrowDownOutlined /> }
      </MenuItem>

      {isMini && (
        <Menu
          anchorEl={menuAnchor.current}
          open={showPopoutMenu}
          onClose={() => setShowPopoutMenu(false)}
          disableScrollLock
          disablePortal
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
        >
          <ListItemText primary={linkText} className={classes.popoutHeader} />
          <Divider sx={{ my: 0.5 }} />
          {(items || []).map((item: any) => (
            <MenuItem className={`${classes.menuItem} ${classes.subMenuMini} ${currentPath === item.href ? classes.subItemMiniSelected : ''}`} key={item.href}>
              <NavLink to={item.href} className={classes.subItemMini}>
                <Translate id={item.text} />
              </NavLink>
            </MenuItem>
          ))}
        </Menu>
      )}

      {!isMini && isExtended && (items || []).map((item: any) => (
        <MenuItem className={`${classes.menuItem} ${classes.subMenu} ${currentPath === item.href ? classes.subItemSelected : ''}`} key={item.href}>
          <NavLink to={item.href} className={classes.subItem}>
            <Translate id={item.text} />
          </NavLink>
        </MenuItem>
      ))}

    </>
  );
};

export default withStyles(styles)(SidebarMenuItemSub);
