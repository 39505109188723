import React, { BaseSyntheticEvent, useCallback, useRef, useState } from 'react';
import { useLocation } from 'react-router-dom';
import MenuItem from '@mui/material/MenuItem';
import isArray from 'lodash/isArray';
import useShopAccessApi, { BlockListEntry } from './useShopAccessApi';
import DocumentTitle from '../components/DocumentTitle';
// @ts-ignore
import FilterWrapper from '../components/FilterWrapper';
// @ts-ignore
import QuickRangeFilter from '../components/QuickRangeFilter';
import Translate from '../components/i18n/Translate';
import ResourceChartView, { ResourceChartViewRef } from '../charts/scaffold/ResourceChartView';
import JournalTable from './JournalTable';
import FromToSelectorWithDateTime from '../components/FromToSelectorWithDateTime';
// @ts-ignore
import FilterTextInput from '../components/FilterTextInput';
// @ts-ignore
import FilterSelect from '../components/FilterSelect';
// @ts-ignore
import Pagination from '../components/Pagination';
import { Range } from '../charts/chartFilterProps';
import useChartFilter from '../charts/useChartFilter';
import SearchableShopSelect from '../components/SearchableShopSelect';
import Shop from '../shop/Shop';

function getShopIDsFromShopIDs(shopIDs: string | string[] | undefined): string[] {
  if (!shopIDs) return [];
  if (isArray(shopIDs)) return shopIDs;
  return [shopIDs];
}

export default function JournalView(props: any) {
  const api = useShopAccessApi();
  const location = useLocation();

  const { filter, updateFilter } = useChartFilter({
    urlQuery: props.location.search,
    autoUpdateUrl: true,
    genericFilterInUrl: ['shopID', 'idFilter', 'typeFilter', 'accessGrantedFilter'],
    defaultValues: {
      genericFilter: {
        idFilter: location?.state?.presetIdFilter,
      },
    },
  });

  const resourceChartViewRef = useRef<ResourceChartViewRef | null>(null);

  const [pageCount, setPageCount] = useState<number | undefined>();
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [fetchPage, setFetchPage] = useState(false);

  const handleFetch = useCallback(async () => {
    if (!filter.range) return null;

    const { journal, totalPages } = await api.getJournal({
      shopIDs: getShopIDsFromShopIDs(filter.genericFilter?.shopID),
      range: filter.range,
      idFilter: filter.genericFilter?.idFilter,
      typeFilter: filter.genericFilter?.typeFilter,
      accessGrantedFilter: filter.genericFilter?.accessGrantedFilter,
      currentPage: fetchPage ? currentPage : 1,
    }) || {};

    setCurrentPage(fetchPage ? currentPage : 1);
    setFetchPage(false);

    if (!journal) return null;

    setPageCount(totalPages);

    const blockList = await api.getBlocklist({});
    const blockListMap = new Map<string, BlockListEntry>(blockList?.list?.map((blockedUser: any) =>
      [blockedUser.credentialsValue, blockedUser]));
    journal.list = journal.list?.map((entry: any) => {
      const blockReason = blockListMap.get(entry.credentialsValue)?.reason;
      return { ...entry, blockReason, isBlocked: !!blockReason };
    });

    return journal;
  }, [api, currentPage, fetchPage, filter]);

  const handleChangePage = useCallback((_: BaseSyntheticEvent, page: number) => {
    setFetchPage(true);
    setCurrentPage(page);
  }, []);

  const handleUpdateIdFilter = useCallback((newFilter: string) => {
    updateFilter({
      genericFilter: {
        ...filter.genericFilter,
        idFilter: newFilter,
      },
    });
  }, [filter.genericFilter, updateFilter]);

  return (
    <React.Fragment>
      <DocumentTitle translationID="shopAccess.headline" />
      <FilterWrapper
        headline={<Translate id="shopAccess.journal.headline" />}
        handleRefresh={() => { resourceChartViewRef.current?.updateData(); }}
        topBarChildren={<></>}
      >
        <FromToSelectorWithDateTime
          range={filter.range}
          hasError={undefined}
          onChange={(event: Range) => updateFilter({ range: event })}
          emitFilled
        />
        <QuickRangeFilter
          range={filter.range}
          onChange={(event: Range) => updateFilter({ range: event })}
        />
        <SearchableShopSelect
          defaultShopId={getShopIDsFromShopIDs(filter.genericFilter?.shopID) || []}
          onChange={(newShops) => {
            updateFilter({
              genericFilter: {
                ...filter.genericFilter,
                shopID: (newShops as Shop[])?.map(s => s.id) || [],
              },
            });
          }}
          showLabel
          multiple
        />
        <FilterSelect
          value={filter.genericFilter?.typeFilter}
          onChange={(event: BaseSyntheticEvent) => {
            updateFilter({
              genericFilter: {
                ...filter.genericFilter,
                typeFilter: event.target.value,
              },
            });
          }}
          label={<Translate id="shopAccess.journal.typeFilter.label" />}
        >
          <MenuItem value="">
            <em><Translate id="filter.none" /></em>
          </MenuItem>
          <MenuItem value="appUserID">
            <Translate id="shopAccess.journal.typeFilter.appUser" />
          </MenuItem>
          <MenuItem value="card">
            <Translate id="shopAccess.journal.typeFilter.card" />
          </MenuItem>
          <MenuItem value="phoneNumber">
            <Translate id="shopAccess.journal.typeFilter.phoneNumber" />
          </MenuItem>
        </FilterSelect>
        <FilterSelect
          value={filter.genericFilter?.accessGrantedFilter}
          onChange={(event: BaseSyntheticEvent) => {
            updateFilter({
              genericFilter: {
                ...filter.genericFilter,
                accessGrantedFilter: event.target.value,
              },
            });
          }}
          label={<Translate id="shopAccess.journal.accessGrantedFilter.label" />}
        >
          <MenuItem value="">
            <em><Translate id="filter.none" /></em>
          </MenuItem>
          <MenuItem value="true">
            <Translate id="shopAccess.journal.accessGrantedFilter.granted" />
          </MenuItem>
          <MenuItem value="false">
            <Translate id="shopAccess.journal.accessGrantedFilter.blocked" />
          </MenuItem>
        </FilterSelect>
        <FilterTextInput
          label={<Translate id="shopAccess.journal.idFilter" />}
          value={filter.genericFilter?.idFilter || ''}
          onChange={(event: BaseSyntheticEvent) => {
            handleUpdateIdFilter(event.target.value);
          }}
        />
      </FilterWrapper>
      <ResourceChartView
        Chart={JournalTable}
        ChartProps={{
          range: filter.range,
          onFocusUser: (credentialsValue: string) => handleUpdateIdFilter(credentialsValue),
        }}
        fetch={handleFetch}
        ref={resourceChartViewRef}
      />
      {!!pageCount && (
        <Pagination
          count={pageCount}
          page={currentPage}
          onChange={handleChangePage}
        />
      )}
    </React.Fragment>
  );
}
