import { FieldValues } from 'react-hook-form';
import {
  defineApi,
  useProjectSpecificApiClient,
} from '../api';
import { removeUndefinedObjectEntries, repairObject } from '../utils/api/repairFormArrays';
import { isSuccessfulOrCustomResponse } from '../utils/statusValidator';

interface Printer {
  printerID: string;
  name: string;
  address: string;
  linePitch: number;
}

export interface ShopPosConfig {
  printers: Printer[]
}

export interface ShopPosData extends ShopPosConfig {
  projectID: string;
  shopID: string;
}

interface ShopPosConfigParams {
  shopID: string;
}

interface UpdateShopPosConfigParams {
  shopID: string;
  data: FieldValues;
}

const sanitizeData = (data: FieldValues) => {
  // NOTE unregistered entries from the form controller are still there
  // but undefined and therefore need to be filtered
  const repairedObject = repairObject(data);

  if (repairedObject.printers?.some((p: any) => !p.name || !p.address || !p.linePitch)) throw new Error('Some required fields are missing.');

  // TODO prevent linePitch from being converted to string. This is a problem with all the forms.
  // This should be solved with the new form engine designed for the admin portal
  //
  // NOTE the printer object is not of type Printer since the line pitch is not yet converted to
  // a number
  repairedObject.printers = repairedObject.printers?.map((printer: any) => {
    if (!printer.name || !printer.address || !printer.linePitch) throw new Error('Some required fields are missing.');
    const linePitch = parseInt(printer.linePitch, 10);
    return { ...printer, linePitch };
  });

  let cleanedData:FieldValues = {
    ...repairedObject,
  };

  cleanedData = removeUndefinedObjectEntries(cleanedData);

  return cleanedData;
};

const useApi = defineApi({
  get: async (client, { shopID }: ShopPosConfigParams) => {
    const { data } : { data: ShopPosData } = await client({
      url: `/${shopID}/configs`,
      method: 'GET',
      // NOTE receiving a 404 is ok since this only means that there is no config yet
      validateStatus: status => isSuccessfulOrCustomResponse(status, [404]),
    });
    return data;
  },
  update: (client, { shopID, data }: UpdateShopPosConfigParams) => {
    const cleanedData = sanitizeData(data);
    client.put(`/${shopID}/configs`, cleanedData);
  },
});

export default function useShopPosConfigApi() {
  const client = useProjectSpecificApiClient({ basePath: '/checkout-devices/shops' });
  return useApi(client);
}
