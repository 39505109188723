import actions from '../../actions/constants';
import shopLabel from '../../shop';
import { DeviceStatus } from '../CheckoutDeviceHelper';

function getShopInformation(device, shops) {
  if (device && device.shop && shops && shops.length) {
    return shops.find(s => device.shop === s.id) || {};
  }
  return {};
}

function mapDevice(device, shops) {
  if (!device) return null;
  const shop = getShopInformation(device, shops);
  return Object.assign({}, device, {
    shortID: device.id?.length >= 4 ? device.id.substring(0, 4) : device.id,
    shopInformation: shop,
    shopLabel: shopLabel(shop) || device.shop,
  });
}

function mapDevices(devices, shops) {
  const mapped = {};
  (devices || []).forEach((device) => {
    if (!mapped[device.shop]) {
      mapped[device.shop] = [];
    }
    mapped[device.shop].push(mapDevice(device, shops));
  });
  return mapped;
}

function mapDevicesStatus(devices, status) {
  const mapped = {};
  (devices || []).forEach((device) => {
    if (!mapped[device.id]) {
      mapped[device.id] = {
        messages: [],
        status: DeviceStatus.Unknown,
        // subcomponents are ok by default
        uiStatus: DeviceStatus.Ok,
        serviceStatus: DeviceStatus.Ok,
        printerStatus: DeviceStatus.Ok,
        terminalStatus: DeviceStatus.Ok,
        posStatus: DeviceStatus.Ok,
      };
    }

    if (device.systemInfo) {
      mapped[device.id].systemInfo = device.systemInfo;
    }

    if (device.lastSeenAt) {
      mapped[device.id].lastSeenAt = device.lastSeenAt;
    }

    if (device.status === 'UP') {
      mapped[device.id].status = DeviceStatus.Online;
    } else if (device.status === 'DEGRADED') {
      mapped[device.id].status = DeviceStatus.Problem;
    } else {
      mapped[device.id].status = DeviceStatus.Offline;
      // if a device is offline we most likely do not know the state of its subcomponents
      // if we do the state is overwritten by an error
      mapped[device.id].uiStatus = DeviceStatus.Unknown;
      mapped[device.id].serviceStatus = DeviceStatus.Unknown;
      mapped[device.id].printerStatus = DeviceStatus.Unknown;
      mapped[device.id].terminalStatus = DeviceStatus.Unknown;
      mapped[device.id].posStatus = DeviceStatus.Unknown;
    }
    if (device.errors && device.errors.length) {
      device.errors.forEach((error) => {
        switch (error.type) {
          case 'printer_error':
            mapped[device.id].printerStatus = DeviceStatus.Problem;
            break;
          case 'ui_component_offline':
            mapped[device.id].uiStatus = DeviceStatus.Problem;
            break;
          case 'service_component_offline':
            mapped[device.id].serviceStatus = DeviceStatus.Problem;
            break;
          case 'pos_component_offline':
            mapped[device.id].posStatus = DeviceStatus.Problem;
            break;
          case 'terminal_error':
            mapped[device.id].terminalStatus = DeviceStatus.Problem;
            break;

          default:
            break;
        }
      });
      mapped[device.id].messages = [...mapped[device.id].messages, ...device.errors];
    }
    // remove all the subcomponents the device does not have
    if (device.kind === 'sco' || device.kind === 'gatekeeper') {
      mapped[device.id].posStatus = DeviceStatus.NA;
    } else if (device.kind === 'sco-ng' || device.kind === 'pos') {
      mapped[device.id].uiStatus = DeviceStatus.NA;
      mapped[device.id].serviceStatus = DeviceStatus.NA;
    }
  });
  return Object.assign({}, mapped, status);
}

export default ({ checkoutDevices, shops }, action) => {
  switch (action.type) {
    case actions.REQUEST_CHECKOUT_DEVICES:
      return Object.assign({}, checkoutDevices, { isFetchingList: true, list: {} });

    case actions.RECEIVED_CHECKOUT_DEVICES: {
      const devices = action.payload && action.payload.devices ? action.payload.devices : [];
      return Object.assign({}, checkoutDevices, {
        isFetchingList: false,
        list: mapDevices(devices, shops),
      });
    }

    case actions.REQUEST_CHECKOUT_DEVICE:
      return Object.assign({}, checkoutDevices, { isFetchingDevice: true, device: {} });

    case actions.RECEIVED_CHECKOUT_DEVICE: {
      return Object.assign({}, checkoutDevices, {
        isFetchingDevice: false, device: mapDevice(action.payload, shops),
      });
    }

    case actions.RECEIVED_CHECKOUT_DEVICES_STATUS: {
      const devices = action.payload && action.payload.devices ? action.payload.devices : [];
      return Object.assign({}, checkoutDevices, {
        status: mapDevicesStatus(devices, checkoutDevices ? checkoutDevices.status : {}),
      });
    }

    default:
      return checkoutDevices;
  }
};
